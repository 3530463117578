
import './App.css';
import {MantineProvider} from "@mantine/core";
import {CardEditor} from "./components/CardEditor";

function App() {



  return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <CardEditor/>
      </MantineProvider>
  );
}

export default App;
