import {Select} from "@mantine/core";
import {IconArrowRight, IconCircle, IconCircleHalf2, IconHash, IconPhone, IconPhoto} from "@tabler/icons-react";
import {useState} from "react";

export const CardEditor = () => {
	const [icon, setIcon] = useState(<IconArrowRight size="1rem"/>);
	const changeBackground = (className) => {
		document.body.className = (className);
	};

	const changeIcon = (selected) => {
		switch (selected){
			case 'Single Color':
				setIcon(<IconCircle size="1rem"/>);
				break;
			case 'Gradient':
				setIcon(<IconCircleHalf2 size="1rem"/>);
				break;
			case 'Image':
				setIcon(<IconPhoto size="1rem"/>);
				break;
		}

	}

	return (
		<>
			<div className={"bg-white p-5 rounded-3xl card flex"}>
				<div className={"w-1.5 rounded-full bg-black/20"}></div>
				<div className={"pl-3 flex flex-col gap-3"}>
					<h1 className={"text-xl"}>Background</h1>
					<div className={"select-wrapper"}>
						<Select
							maw={320}
							mx="auto"
							// label="Type"
							placeholder="Select background type"
							data={['Single Color', 'Gradient', 'Image']}
							variant={"filled"}
							transitionProps={{ transition: 'pop-top-left', duration: 80, timingFunction: 'ease' }}
							styles={{ rightSection: { pointerEvents: 'none' } }}
							radius={"md"}
							icon={icon}
							onChange={(value) => changeIcon(value)}
							withinPortal
						/>
					</div>
					<div className={"flex gap-2"}>
						<button onClick={() => changeBackground('bg-1')} className={"bg-1"}></button>
						<button onClick={() => changeBackground('bg-2')} className={"bg-2"}></button>
						<button onClick={() => changeBackground('bg-3')} className={"bg-3"}></button>
						<button onClick={() => changeBackground('bg-4')} className={"bg-4"}></button>
						<button className={"w-10 h-10 rounded-3xl flex items-center justify-center hover:rotate-180 hover:scale-110 transition-transform ease-out"}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
								<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
							</svg>
						</button>
					</div>
				</div>
			</div>
		</>
	)
}